<div class="title" i18n>Hi!</div>
@if (organizations.length === 0 && !searching) {
  @if (!isLoading) {
    <div class="disabled-user-text-container">
      <span i18n>
        It seems that your user has been disabled and there are no active organizations associated with your
        account.
      </span>
      <span i18n>
        If you think this might be a mistake, please get in contact with your organization administrator.
      </span>
    </div>
  }
} @else {
  <div class="subtitle" i18n>Choose the organization to continue.</div>
  @if (showSearch) {
    <div class="search">
      <app-input (valueChange)="onSearchChanged($event)">
        <fa-icon [icon]="['fas', 'search']" prefix></fa-icon>
      </app-input>
    </div>
  }
  <div class="organization-list">
    @if (isLoading) {
      <div class="spinner"></div>
    }
    @for (organization of organizations; track organization.id) {
      <a
        class="organization"
        [class.inactive]="isRedirecting"
        (click)="organizationClick(organization, $event)"
        (auxclick)="organizationClick(organization, $event)">
        @if (organization.avatar) {
          <div class="avatar" [style.background-image]="'url(' + organization.avatar + ')'"></div>
        } @else {
          <div class="avatar without-image">
            <fa-icon [icon]="['fas', 'building']"></fa-icon>
          </div>
        }
        <div class="info">
          <div class="name">{{ organization.name }}</div>
        </div>
        <span *ngIf="redirectingTo(organization)" class="redirecting" i18n>Redirecting...</span>
        <fa-icon [icon]="['far', 'angle-right']"></fa-icon>
      </a>
    }
  </div>
}
@if (!isRedirecting) {
  <div class="buttons">
    @if (!isLoggingOut) {
      <button class="secondary-button" (click)="logOut()">
        <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
        <span i18n>Use another account</span>
      </button>
    } @else {
      <div class="spinner"></div>
    }
  </div>
}
@if (isError) {
  <div class="error" i18n>Could not load organization list</div>
}
@if (licenseError) {
  <app-modal (closeModal)="closeDialog()">
    <ng-container header>
      <div class="modal-title">
        <fa-icon [icon]="['fal', 'info-circle']"></fa-icon>
        <span i18n>License error</span>
      </div>
    </ng-container>
    <ng-container content>
      <div class="modal-content" i18n>You don't have a valid license in the selected organization.</div>
    </ng-container>
  </app-modal>
}

@if (duplicatedDeviceError) {
  <app-modal [maxWidth]="600" (closeModal)="closeDialog()">
    <ng-container header>
      <div class="modal-title">
        <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
        <span i18n>Oops! Your account is currently logged in elsewhere.</span>
      </div>
    </ng-container>
    <ng-container content>
      <div class="modal-content" i18n>
      It seems that you've logged in on another browser or device.
      Sytex allows only one active session at a time. 
      To continue, please close this session and log in again. 
      This will disconnect the session on the other browser.</div>
    </ng-container>

    <ng-container footer>
      <div class="modal-footer">
        <button class="rounded-button accent" (click)="logOut()">
            <span i18n>Log out</span>
        </button>
      </div>
    </ng-container>
  </app-modal>
}
